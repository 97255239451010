@media (max-width: 1000px) {
  .App {
    width:100%;
    padding:auto;
    margin:auto
  }

}

@media (min-width: 1000px) {
  .App {
    width:50%;
    padding:auto;
    margin:auto
  }

}

.qrReader {
height:10%;

  padding:0px;
  margin:0px;
  background-color: black;
  height:10%;
}

.qrReaderOff {
  display:none
}